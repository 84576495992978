import { ADDUSER, UPDATEUSER } from "./actionTypes";
export const AddUser = (User) => {
  return {
    type: ADDUSER,
    payload: User,
  };
};
export const UpdateUser = (User) => {
  return {
    type: UPDATEUSER,
    payload: User,
  };
};
