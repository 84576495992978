const { ROLES, PERMISSION_CONFIGS } = require("constant/constants");

const getPermissionConfigs = (Permission) => {
  //console.log(Permission,'Permission in config')
  let configs = {};

  Object.entries(PERMISSION_CONFIGS).forEach(([key, value]) => {
    const exist = Permission.some((state) => state.name === key);
    //console.log('Exist',exist)
    //console.log(key,'key')
    configs[key] = exist;
  });
  return configs;
};

export { getPermissionConfigs };
