import axios from "axios";

import { apiBaseUrl } from "constant/apiBaseUrl";
import { addLoading, removeLoading } from "store/loading/action";
import { store } from "../store/index";
import toastr from "toastr";
import authHeader from "./auth_token";
toastr.options = {
  positionClass: "toast-top-right",
};
let removeToastOn = ["upload-image"];

const axiosApi = axios.create({
  baseURL: apiBaseUrl,
});

axiosApi.interceptors.request.use(
  (config) => {
    let newHeaders;
    const url = config.url;
    const headers = authHeader();
    if (!config?.hideLoader) {
      // //console.log("Loading:    ", url);
      store.dispatch(addLoading(url));
    }
    newHeaders = {
      ...config.headers,
      ...headers,
      "Content-Type": config.headers["Content-Type"]
        ? config.headers["Content-Type"]
        : "application/json",
    };
    config.headers = newHeaders;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const responseSuccess = (response) => {
  const url = response.config.url;
  store.dispatch(removeLoading(url));
  if (response.config.method !== "get" && !removeToastOn.includes(url)) {
    if (response.message) {
      toastr.success(response.message);
    } else if (response.data.message) {
      toastr.success(response.data.message);
    }
  }
  // //console.log(response);
  if (response.data) {
    return response.data;
  }
  return response;
};

const responseError = (error) => {
  const url = error.config.url;

  if (error.response?.status === 401) {
    if (window.location.pathname !== "/login") {
      localStorage.clear();
      window.location.href = "/login";
      return;
    }

    // Add toastr message for unauthorized access
  }
  store.dispatch(removeLoading(url));
  if (error?.response?.data?.error) {
    let formatedErrors = foramteError(error.response.data.error);

    formatedErrors.map((error) => toastr.error(error));
  } else if (error?.response?.data?.message) {
    toastr.error(error.response.data.message);
  } else if (error?.message) {
    toastr.error(error.message);
  }

  return Promise.reject(error);
};

axiosApi.interceptors.response.use(
  (response) => responseSuccess(response),
  (error) => responseError(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export default axiosApi;

const foramteError = (error) => {
  if (typeof error === "string") {
    return [error];
  }
  let formatedErrors = [];
  Object.keys(error).forEach((key) => {
    let errorArray = error[key];
    let formatedErrorMessage = "";
    errorArray.forEach((errorMessage) => {
      formatedErrorMessage += `${errorMessage} `;
    });
    formatedErrors.push(`${key} : ${formatedErrorMessage}`);
  });
  return formatedErrors;
};
