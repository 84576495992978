export const calculateElapsedTime = (updatedAt) => {
  const updatedTime = new Date(updatedAt);
  const currentTime = new Date();
  const difference = currentTime - updatedTime;

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  const differenceInSeconds = Math.floor(difference / 1000);

  if (differenceInSeconds >= secondsInDay) {
    const days = Math.floor(differenceInSeconds / secondsInDay);
    return `${days} ${days > 1 ? "days" : "day"} ago`;
  } else if (differenceInSeconds >= secondsInHour) {
    const hours = Math.floor(differenceInSeconds / secondsInHour);
    return `${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  } else if (differenceInSeconds >= secondsInMinute) {
    const minutes = Math.floor(differenceInSeconds / secondsInMinute);
    return `${minutes} ${minutes > 1 ? "minutes" : "minute"} ago`;
  } else {
    return `${differenceInSeconds} ${
      differenceInSeconds > 1 ? "seconds" : "second"
    } ago`;
  }
};
