import { LoadNotification, SETLENGTH } from "./actionTypes";
const initialState={
    NOTIFICATION:"",
    LENGTH:0
}

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoadNotification:
      const notification = action.payload;
      return {
        ...state,
        NOTIFICATION: notification,
        LENGTH: notification?.length
      };
    case SETLENGTH:
    return {
        ...state,
        LENGTH: 0
      };
    default:
      return state;
  }
};
  export default NotificationReducer
 