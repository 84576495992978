import { act } from "react";
import { UpdateUser } from "./action";
import { ADDUSER } from "./actionTypes";
const nameInitialState = {
  User: {},
};
export const UserReducer = (state = nameInitialState, action) => {
  switch (action.type) {
    case ADDUSER:
      return {
        ...state,
        User: action.payload,
      };

    case UpdateUser:
      return {
        ...state,
        User: action.payload,
      };
    default:
      return state;
  }
};
