import { ADD_Token } from "./actionTypes";
const initialState={
    UserToken:""
}

const TokenReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_Token:
        return {
          ...state,
          UserToken: action.payload
        };
      default:
        return state;
    }
  };
  export default TokenReducer
