import React from 'react';
import { Spinner } from 'reactstrap'; // Assuming you are using Bootstrap's Spinner component

const Loader= () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
        zIndex: 999, // Higher zIndex to overlay on other content
      }}
    >
      <Spinner className="ms-2" color="primary" />
    </div>
  );
}

export default Loader;
