import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect, useSelector } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap

// Import menuDropdown

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/ntc.jpeg";

// import images

//i18n
import { withTranslation } from "react-i18next";

const Header = (props) => {
  const [notification, setNotification] = useState([]);
  const [length, setLength] = useState(0);

  const notifications = useSelector((state) => state.NotificationReducer);

  useEffect(() => {
    setNotification(notifications);
    setLength(notifications?.NOTIFICATION?.length);
  }, [notifications]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header w-100 d-flex justify-content-between align-items-center h-100">
          <div className="navbar-brand-box d-flex align-items-center gap-4">
            <div className="logo-container">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="50" />
                </span>
              </Link>
            </div>

            <div className="text-container flex-grow-1 text-center">
              <div className="mt-4 mt-sm-0">
                <h4 className="fs-4 mb-0">National Technology Council</h4>
              </div>
            </div>

            <Link to="/" className="logo logo-light"></Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
            data-toggle="collapse"
            onClick={() => {
              //toggleNavigationMenu()
              props.dropshow();
            }}
            data-target="#topnav-menu-content">
            <i className="fa fa-fw fa-bars" />
          </button>

          <div className="d-flex">
            {<NotificationDropdown lengths={length} />}
            <ProfileMenu setRole={props.setRole} />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  dropshow: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
