import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
//Import images
import API from "helper/apihelper";

import { useSSR, withTranslation } from "react-i18next";
import { ROLES, Roles } from "constant/constants";
import { LoadNotification, SETLENGTH } from "store/Notification/actionTypes";
import { calculateElapsedTime } from "Utility/ElapsedTime";
const ADMIN_ROLES = [
  Roles.ADMIN,
  ROLES.OFFICE_ASSITANT,
  ROLES.DEPUTY_DIRECTOR,
  ROLES.CHAIR_MAN,
  ROLES.ADMIN,
];
const isUserAdmin = (role) => {
  return ADMIN_ROLES.includes(role);
};
const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notification, setNotification] = useState([]);

  const [length, setLength] = useState(0);
  const role = localStorage.getItem("Role");
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const isAdmin = isUserAdmin(role);
  const notifications = useSelector((state) => state.NotificationReducer);

  useEffect(() => {
    dispatch({
      type: LoadNotification,
      payload: notification.adminNotfications,
    });
  }, []);

  useEffect(() => {
    setNotification(notifications);

    setLength(notifications.LENGTH);
  }, [notifications, role]);
  const markAsRead = async () => {
    const url =
      role === ROLES.ADMIN
        ? `Admin-user/mark/notification`
        : isAdmin
        ? "user/mark/notification"
        : role === ROLES.AIC
        ? `aic/mark/notification`
        : `user/mark/notification`;
    try {
      // Check if notifications exist and their LENGTH is greater than zero
      console.log("Notification Url for mark as read", url);
      await API.post(url);
    } catch (e) {}

    setLength(null);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenu(false);
      }
    };
    const handleScroll = () => {
      setMenu(false);
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const setNotificationMenu = () => {
    setMenu(!menu);
    dispatch({
      type: SETLENGTH,
      payload: 0,
    });
    markAsRead();
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setNotificationMenu()}
        className="dropdown d-inline-block"
        tag="li"
        innerRef={dropdownRef}>
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown">
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {length ? length : ""}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to={"/notification"} onClick={() => setMenu(!menu)}>
                  <a href="#" className="small">
                    {" "}
                    View All
                  </a>
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notification &&
            notification.NOTIFICATION &&
            notification.NOTIFICATION.length > 0 ? (
              <>
                {notification.NOTIFICATION.sort(
                  (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                ).map((item, index) => (
                  <Link
                    to=""
                    className="text-reset notification-item"
                    key={index}>
                    <div className="d-flex">
                      <div className="avatar-xs me-3"></div>
                      <div className="flex-grow-1">
                        {item.first_name ? (
                          <h6 className="mt-0 mb-1">
                            {props.t(item?.first_name + " " + item?.last_name)}
                          </h6>
                        ) : null}

                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{props.t(item.message)}</p>
                          {item?.updated_at ? (
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {calculateElapsedTime(item?.updated_at)}{" "}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
                <div className="p-2 border-top d-grid">
                  <Link
                    className="btn btn-sm btn-link font-size-14 text-center"
                    to="#"
                    onClick={() => {
                      markAsRead();
                      setMenu(!menu);
                    }}>
                    <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                    <span key="t-view-more">{props.t("Mark As Read")}</span>
                  </Link>
                </div>
              </>
            ) : null}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to={"/notification"}
              onClick={() => setMenu(!menu)}>
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);
