export default function authHeader() {
  const token = localStorage.getItem("TOKEN");
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {};
  }
}
