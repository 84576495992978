import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

// Redux
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

import { LOCAL_STORAGE_KEYS } from "constant/constants";
import { DefaultAvatar } from "../../../Images";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  //const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
  // //console.log(user,'User Name is')
  const user = useSelector((state) => state?.UserReducer?.User);
  const name = user.first_name + " " + user.last_name;

  const Logout = () => {
    localStorage.clear();
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block">
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={user?.avatar ?? DefaultAvatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/EditProfile" className="dropdown-item">
            <i className="bx  font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Profile")}</span>
          </Link>

          <Link to="/login" className="dropdown-item" onClick={() => Logout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
