import React from "react";

import { Navigate } from "react-router-dom";

const NoAuthMiddleware = (props) => {
 


  
  if (localStorage.getItem("TOKEN")) {
    return <Navigate to="/dashboard" />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};


export default NoAuthMiddleware;
