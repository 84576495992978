import { ADDLOADING,RemoveLOADING } from "./actionTypes";
const nameInitialState = {
    error:"",
    loading:false,
    loadingList:[]
}
export const loadingReducer = (state = nameInitialState, action) => {
    switch (action.type) {
        case ADDLOADING:
            const URL=action.payload
            if(state.loadingList.includes(URL))
            {
                return state
            }
            return{
                ...state,
                loading:true,
                loadingList:[...state.loadingList,action.payload]
            }
            
        case RemoveLOADING:
            const index=state.loadingList.indexOf(action.payload)
            if(index>-1)
            {
                state.loadingList.splice(index,1)
            }
            if(state.loadingList.length===0)
            {
                return{
                    ...state,
                    loading:false,
                    loadingList:[]
                }
            }
            return {
                ...state,
                loading: true,
                loadingList: [...state.loadingList],
              }
           
        default:
            return state
    }
}