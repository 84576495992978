import { GET_CHARTS_DATA } from "./actionTypes";

const INIT_STATE = {
  chartsData: ["yearly"],
  chartsDataError: null, // Add a field to handle errors related to chartsData
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHARTS_DATA:
      if (action.payload && action.payload.data) {
        return {
          ...state,
          chartsData: action.payload.data,
          chartsDataError: null, // Reset error when data is successfully fetched
        };
      } else if (action.payload && action.payload.error) {
        return {
          ...state,
          chartsDataError: action.payload.error,
        };
      }
      return state; // Return the current state if no valid data or error is found

    // Handle other action types if necessary

    default:
      return state;
  }
};

export default Dashboard;
