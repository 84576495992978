import { combineReducers } from "redux";
import Layout from "./layout/reducer";
import Login from "./auth/login/reducer";
import Dashboard from "./dashboard/reducer";
import Store from "./Store";

import TokenReducer from "./AuthorizationToken/reducer";
import { UserReducer } from "./UserDetail/reducer";
import { loadingReducer } from "./loading/reducer";
import DataReducer from "./AicProfile/reducer";
import NotificationReducer from "./Notification/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,

  UserReducer,
  TokenReducer,
  loadingReducer,

  DataReducer,
  Dashboard,

  NotificationReducer,

  role: Store,
});

export default rootReducer;
