import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter, Router } from "react-router-dom";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// Layouts
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "react-rangeslider/lib/index.css";

import NoAuthMiddleware from "routes/PublicMiddleware";
import { ErrorBoundary } from "react-error-boundary";
import "toastr/build/toastr.min.css";
const App = () => {
  const Layout = HorizontalLayout;

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NoAuthMiddleware>
                <NonAuthLayout>{route.component}</NonAuthLayout>
              </NoAuthMiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
