const InitialState = {
  Educationload: false,
  Experienceload: false,
  publicationload: false,
  trianingload: false,
};

const DataReducer = (state = InitialState, action) => {
  switch (action.type) {
    case "LOADDATAEducation":
      return { ...state, Educationload: true };

    case "RemoveEducation":
      return { ...state, Educationload: false };
    case "LOADDATAExperience":
      return { ...state, Experienceload: true };

    case "RemoveExperience":
      return { ...state, Experienceload: false };
    case "LOADDATAPublication":
      return { ...state, publicationload: true };

    case "RemovePublication":
      return { ...state, publicationload: false };
    case "LOADDATATrianing":
      return { ...state, trianingload: true };

    case "RemoveTrianing":
      return { ...state, trianingload: false };
    default:
      return state;
  }
};

export default DataReducer;
